<template>
<div class="mainform fcsForm">
    <div class="mainHeader">
      创建FCS
      <span @click="back" class="back">返回></span>
    </div>
  <div class="form">
    <el-form ref="EvaluationCriteriaForm" class="content bascform" :model="formdata" :rules="formRules" label-width="120px">
      <div v-if="fieldShow.bidCode!==false" class="col col8">
      <el-form-item label="明细" prop="bidScoringList">
        <el-upload
          ref="upload"
          name="file"
          :auto-upload="true"
          :action= 'postUrl'
          :show-file-list="false"
          :on-exceed="handleExceed"
          :on-change="handleChansge"
          :on-success="handSuccess"
          :on-error="handError"
          :headers="tokenHeader"
        >
          <el-button size="small" type="primary" plain>导入excel</el-button>
        </el-upload>
        <div class="tableList">
          <div class="table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column :show-overflow-tooltip="true" prop="materialCode" label="物料编号">
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="materialDesc" label="物料描述">
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="materialNum" label="数量">
                <template #default="scope">
                  <el-input size="small" v-model="scope.row.materialNum" placeholder="请输入内容"></el-input>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="cycle" label="周期">
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="supplierName" label="供应商">
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="buyer" label="采购员">
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template #default="scope">
                  <template v-if="tableData.length === 1">
                    <el-button v-if="false" size="mini" plain type="primary" @click="addFcs(scope.$index)" icon="el-icon-plus" circle></el-button>
                  </template>
                  <template v-else-if="tableData.length === scope.$index + 1">
                    <el-button size="mini" plain type="primary" @click="removeFcs(scope.$index)" icon="el-icon-minus" circle></el-button>
                    <el-button v-if="false" size="mini" plain type="primary" @click="addFcs(scope.$index)" icon="el-icon-plus" circle></el-button>
                  </template>
                  <template v-else>
                    <el-button size="mini" plain type="primary" @click="removeFcs(scope.$index)" icon="el-icon-minus" circle></el-button>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form-item>
      </div>
    </el-form>
    <div class="action" >
      <el-button @click="submit" class="submit" type="primary" size="medium">提交</el-button>
    </div>
  </div>
</div>
</template>

<script>
import { request, baseURL } from '@/assets/js/http.js'
import { TableData } from './js/setting.js'
export default {
  name: 'FscForm',
  data: function () {
    return {
      postUrl: baseURL + '/api/supplier/fcs/upload/fcs',
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      formdata: {},
      formRules: {},
      form: {},
      rowData: { ...TableData },
      tableData: [{ ...TableData }],
      fieldShow: {},
      isreadonly: false
    }
  },
  methods: {
    submit () {
      request('/api/supplier/fcs/save', 'post', this.tableData).then((Response) => {
        if (Response.code === '200') {
          this.back()
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        }
      })
    },
    addFcs () {
      this.tableData.push({ ...this.rowData })
    },
    removeFcs (index) {
      this.tableData.splice(index, 1)
    },
    back: () => {
      window.history.back()
    },
    handleExceed (e) {
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$notify.error({
          title: '错误',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$notify.error({
        title: '错误',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.tableData = response.data
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/tableList.scss';
  @import '@/assets/css/elForm.scss';
.fcsForm .action{
    margin-bottom: 20px;
  }
.fcsForm .tableList{
    padding: 0 0px;
    margin-top:20px;
    :deep(.table){
      .el-table td {
        padding: 4px 0;
        .el-select{
          width: 100%;
        }
        .el-input__inner {
          border: none;
          background: none;
          padding: 0;
        }
        .el-button{
          padding: 3px 4px;
        }
      }
    }
  }
</style>
